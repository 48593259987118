import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userInfo: null,
};

export const authSlice = createSlice({
    initialState,
    name: 'authSlice',
    reducers: {
        logout: (state) => {
            Object.assign(state, initialState)
            localStorage.removeItem('user')
        },
        setUser: (state, { payload }) => {
            state.userInfo = payload;
        },
    },
});

export default authSlice.reducer;

export const { logout, setUser } = authSlice.actions;