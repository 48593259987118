import { Box, Button, Typography } from '@mui/material';

import { Link } from "react-router-dom";
import React from "react";

export default function NotFound() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '80vh',
      }}
    >
      <Typography variant="h1" color='secondary' sx={{ fontWeight: 400 }}>
        404
      </Typography>
      <Typography variant="h6" sx={{ textAlign: 'center' }}>
        The page you're looking for doesn't exist.
      </Typography>
      <Button
        component={Link}
        color='secondary'
        variant="contained"
        to="/"
        sx={{ mt: 2, borderRadius: 25 }}
      >Go Home</Button>
    </Box>

  );
}
