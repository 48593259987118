import React from "react";
import ReactApexChart from "react-apexcharts";

function LineChart({ chartData }) {


    const chartOptions = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        tooltip: {
            theme: "dark",
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
        },
        xaxis: {
            //type: "datetime",
            categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
            labels: {
                style: {
                    colors: "#3c3d3a",
                    fontSize: "12px",
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#3c3d3a",
                    fontSize: "12px",
                },
            },
        },
        legend: {
            show: false,
        },
        grid: {
            strokeDashArray: 5,
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "light",
                type: "vertical",
                shadeIntensity: 0.5,
                gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                inverseColors: true,
                opacityFrom: 0.8,
                opacityTo: 0,
                stops: [],
            },
            colors: ["#6e5f7e", "#ef737f"],
        },
        colors: ["#6e5f7e", "#ef737f"],
    };

    return (
        <ReactApexChart
            options={chartOptions}
            series={chartData}
            type="area"
            width="100%"
            height={400}
        />
    );
}

export default LineChart