import { Box, CircularProgress, FormControl, IconButton, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react'

import AuthContext from '../context/AuthContext';
import { logUserIn } from '../services/auth.service'
import { loginSchema } from '../utils/schemas'
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function Login() {

    const { user, setUser } = useContext(AuthContext);
    const navigate = useNavigate()

    const [isLoading, setisLoading] = useState(false)

    useEffect(() => {
        const isAlreadyLogged = localStorage.getItem("user");
        if (isAlreadyLogged) {
            navigate('/');
        }
    }, [])


    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: loginSchema,
        onSubmit: (values, formikHelpers) => {
            if (values) {
                const { username, password } = values;
                setisLoading(true);
                handleLoginSubmit(username, password)
                formikHelpers.resetForm();
            }
        },
    });

    const handleLoginSubmit = async (user, pass) => {
        try {
            const response = await logUserIn(user, pass);
            if (response) {
                //console.log(response);
                const { status } = response;
                if (status != "success") return;
                setUser('admin');
                localStorage.setItem("user", "admin");
                navigate('/')
            }
        } catch (error) {
            console.log(error);
        } finally {
            setisLoading(false)
        }
    }




    return (
        <Box sx={{
            background: 'linear-gradient(90deg, #6e5f7e, #ef737f)',
            width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}>
            {
                isLoading ? (
                    <Box sx={{ background: 'linear-gradient(90deg, #6e5f7e, #ef737f)', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh' }}>

                        <CircularProgress
                            color='secondary'
                            size={40}
                            thickness={4}
                        />
                    </Box>
                ) : (
                    <FormControl className="m-login-form" style={{
                        minWidth: '320px',
                        minHeight: '120px',
                        margin: '0 auto',
                        background: 'white',
                        padding: '40px',
                        gap: '20px',
                        borderRadius: '5px',
                        boxShadow: 'rgba(0, 0, 0, 0.05) 3px 20px 27px 27px'
                    }}>
                        <Typography component="h2" level="h2" textAlign={'center'}>Admin Login</Typography>
                        <TextField
                            variant="outlined"
                            name="username"
                            placeholder="Username"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            helperText={formik.touched.username && formik.errors.username}
                            type="text" />
                        <TextField
                            variant="outlined"
                            name="password"
                            placeholder="Password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            helperText={formik.touched.password && formik.errors.password}
                            type="password" />
                        <Box sx={{ margin: '0 auto', marginTop: '20px' }}>
                            <a className="btn-common" style={{ textAlign: 'center' }} onClick={formik.handleSubmit}>
                                Log In
                            </a>
                        </Box>
                    </FormControl >
                )
            }

        </Box >
    )
}

export default Login