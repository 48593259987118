import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_URL || 'http://localhost:3001/api',
        //baseUrl: 'http://localhost:3001/', // test URL
        prepareHeaders: (headers) => {
            headers.set("content-type", "application/json");

        },
    }),
    //refetchOnFocus: true, // refetch on window focus
    tagTypes: ['account', 'categories'],
    endpoints: () => ({}),
})
