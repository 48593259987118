import axios from 'axios';

const apiUrl = process.env.REACT_APP_URL || 'http://localhost:3000/api';

const logUserIn = async (username, password) => {
    const response = await axios
        .post(
            `${apiUrl}/auth/login`,
            {
                username: username,
                password: password
            },
            {
                headers: {
                    'content-type': 'application/json',
                },
            }
        )
    return response?.data;
}


export { logUserIn }