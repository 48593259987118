import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#6e5f7e',
            light: '#6e5f7ecc',
            dark: '#4f455b'
        },
        secondary: {
            main: '#ef737f',
            light: '#ea8a93',
            dark: '#f06976'
        }

    },
    components: {
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    // color: "red"
                }
            }
        }
    },
})


export default theme;