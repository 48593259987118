import {
  Box,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { newAccountValidationSchema, newCategorySchema } from '../utils/schemas';
import { useAddNewAccountMutation, useGetAccountsQuery, useRemoveAccountMutation, useUpdateAccountMutation } from '../store/accounts/accountsApi';
import { useAddNewCategoryMutation, useGetCategoriesQuery } from '../store/categories/categoriesApi';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AuthContext from '../context/AuthContext';
import MaterialReactTable from 'material-react-table';
import PresentationTable from './PresentationTable';
import { useFormik } from 'formik';

const DashboardTable = () => {

  const { user, setUser } = useContext(AuthContext);

  useEffect(() => {
    const savedUser = localStorage.getItem("user");
    if (savedUser)
      setUser(savedUser)
  }, [user])

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [accountToDelete, setaccountToDelete] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100, //customize the default page size
  });

  const handleOnCloseNewAccountModal = () => {
    setCreateModalOpen(!createModalOpen);
    formik.resetForm();
  };

  const handleOnCloseDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const handleOnCloseCategoryModal = () => {
    setCategoryModalOpen(!categoryModalOpen);
    categoryForm.resetForm();
  };

  /* RTK QUERY */

  const {
    data: accounts,
    isLoading: isAccountListLoading,
    error: getAccountsError,
  } = useGetAccountsQuery()

  const {
    data: categories,
    isLoading: isCategoryListLoading,
    error: getCategoriesError,
  } = useGetCategoriesQuery()

  const [addNewAccount] = useAddNewAccountMutation();
  const [updateAccount] = useUpdateAccountMutation();
  const [removeAccount] = useRemoveAccountMutation();
  const [addNewCategory] = useAddNewCategoryMutation();

  const isTableLoading = isAccountListLoading || isCategoryListLoading;

  /* END RTK QUERY */

  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    const { _id } = row.original;
    const { category } = values;
    const categoryID = getCategoryId(category)
    if (category) {
      await updateAccount({ _id, categoryID })
    }
    exitEditingMode(); //required to exit editing mode
  };

  const getCategoryId = (name) => {
    const match = categories.filter((category) => category.categoryName === name)
    return match[0]?._id
  }

  const columns = useMemo(() => [
    {
      accessorKey: 'index',
      header: 'Rank',
      size: 50,
      accessorFn: (row, i) => i + 1,
      muiTableBodyCellEditTextFieldProps: {
        disabled: true
      }
    },
    {
      accessorKey: "handleName",
      header: "Handle Name",
      Cell: ({ cell }) => {
        return (
          <Link
            sx={{
              transition: 'all 0.1s ease',
              textDecoration: 'none',
              "&:hover": {
                // color: '#ef737f',
              },
            }}
          //href={`/accounts/${cell.getValue()}`}
          >
            {cell.getValue()}
          </Link >);
      },
    },
    {
      accessorKey: "category",
      header: "Category",
      accessorFn: (row, i) => row.categories.length > 0 ? row.categories[0].categoryName : '',
      muiTableBodyCellEditTextFieldProps: {
        select: true,
        children: categories?.map(({ _id, categoryName }) => (
          <MenuItem key={categoryName} value={categoryName}>
            {categoryName}
          </MenuItem>
        )),
      },
    },
    {
      accessorKey: "latestFollowerCount",
      header: "Followers",
      accessorFn: (row, i) => Number(row.latestFollowerCount),
      muiTableBodyCellEditTextFieldProps: {
        disabled: true
      },
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: 'followerDifference',
      header: '24H Growth',
      Cell: ({ cell }) => {
        let perCentageChangeCopy;
        const { followerDifference, percentageChange } = cell.row.original;
        const indexCharacter = String(Number(followerDifference))[0];
        const isGrowthPositive = indexCharacter !== '-';
        const followerDifferenceCopy = isGrowthPositive ? Number(followerDifference) : String(followerDifference).substring(1)

        if (isGrowthPositive) {
          if (indexCharacter == '0') {
            perCentageChangeCopy = String(Number(percentageChange)) + ' %'
          } else {
            perCentageChangeCopy = String(percentageChange).substring(0, 5) + ' %'
          }
        } else {
          perCentageChangeCopy = String(Number(percentageChange)).substring(1, 6) + ' %'
        }

        return (
          <Box >
            <Box paddingLeft={'7px'} marginBottom={'-5px'} sx={{ fontFamily: "Circular-Bold", color: isGrowthPositive ? '#16c784' : '#ef737f' }}>
              {followerDifferenceCopy}
            </Box>
            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} sx={{ color: isGrowthPositive ? '#16c784' : '#ef737f', textAlign: 'left', fontSize: '12px' }}>
              {isGrowthPositive ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              {perCentageChangeCopy}
            </Box>
          </Box>
        )
      },
      accessorFn: (row, i) => Number(row.followerDifference),
      muiTableBodyCellEditTextFieldProps: {
        disabled: true
      },
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        // align: 'left',
      },
    },
    {
      accessorKey: 'sevenDayFollowerDifference',
      header: '7D Growth',
      accessorFn: (row, i) => Number(row.sevenDayFollowerDifference),
      muiTableBodyCellEditTextFieldProps: {
        disabled: true
      },
      Cell: ({ cell }) => {
        const { latestFollowerCount, sevenDayPreviousFollowerCount, sevenDayPercentageChange, sevenDayFollowerDifference } = cell.row.original;
        const difference = Number(latestFollowerCount) - Number(sevenDayPreviousFollowerCount);
        const isGrowthPositive = difference >= 0;
        return (
          <Box >
            <Box paddingLeft={'7px'} marginBottom={'-5px'} sx={{ fontFamily: "Circular-Bold", color: isGrowthPositive ? '#16c784' : '#ef737f' }}>
              {String(Math.abs(difference))}
            </Box>
            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} sx={{ color: isGrowthPositive ? '#16c784' : '#ef737f', textAlign: 'left', fontSize: '12px' }}>
              {isGrowthPositive ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              {String(Math.abs(sevenDayPercentageChange)).substring(0, 5) + ' %'}
            </Box>
          </Box>
        )
      }
    }
  ]);

  const formik = useFormik({
    initialValues: {
      handleName: "",
      category: "",
    },
    validationSchema: newAccountValidationSchema,
    onSubmit: async (values, formikHelpers) => {
      if (values) {
        const { handleName, category } = values;
        const categoryId = getCategoryId(category)
        await addNewAccount({ handleName, category: categoryId });
        setCreateModalOpen(false);
        formikHelpers.resetForm();
      }
    },
  });

  const categoryForm = useFormik({
    initialValues: {
      newCategory: "",
    },
    validationSchema: newCategorySchema,
    onSubmit: async (values, formikHelpers) => {
      if (values) {
        await addNewCategory({ 'categoryName': values.newCategory })
        setCategoryModalOpen(false);
        formikHelpers.resetForm();
      }
    },
  });

  return (
    <>
      {/* DELETE ACCOUNT MODAL */}

      <Dialog open={deleteModalOpen} onClose={handleOnCloseDeleteModal}>
        <DialogContent>
          <h3 style={{ textAlign: "center" }}>
            Are you sure you want to delete?
          </h3>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { my: 1 },
            }}
            noValidate
            className="new-account-form"
          >
            <div className="cta-wrap">
              <a
                className="btn-common btn-dark"
                onClick={() => {
                  setaccountToDelete(null);
                  handleOnCloseDeleteModal();
                }}
              >
                Cancel
              </a>
              <a
                className="btn-common"
                onClick={async () => {
                  await removeAccount(accountToDelete)
                  handleOnCloseDeleteModal();
                }}
              >
                Continue
              </a>
            </div>
          </Box>
        </DialogContent>
      </Dialog>

      {/* CREATE ACCOUNT MODAL */}

      <Dialog open={createModalOpen} onClose={handleOnCloseNewAccountModal}>
        <DialogContent>
          <h3 style={{ textAlign: 'center' }}>Create new</h3>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { my: 1 },
            }}
            noValidate
            className="new-account-form"
          >
            <FormControl >
              <TextField
                fullWidth
                id="handleName"
                name="handleName"
                label="Handle Name"
                value={formik.values.handleName}
                onChange={formik.handleChange}
                helperText={formik.touched.handleName && formik.errors.handleName}
              />
            </FormControl>
            <FormControl >
              <InputLabel id="demo-simple-select-helper-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={formik.values.category}
                name='category'
                label="Category"
                onChange={formik.handleChange}
              >
                {categories?.map(({ _id, categoryName }) => (
                  <MenuItem key={categoryName} value={categoryName}>
                    {categoryName}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.category && formik.errors.category && (
                <FormHelperText>{formik.errors.category}</FormHelperText>
              )}
            </FormControl>
            <div className="cta-wrap">
              <a className="btn-common btn-dark" onClick={handleOnCloseNewAccountModal}>
                Cancel
              </a>
              <a className="btn-common" onClick={formik.handleSubmit}>
                Submit
              </a>
            </div>
          </Box>
        </DialogContent>
      </Dialog>

      {/* ADD CATEGORY MODAL */}

      <Dialog open={categoryModalOpen} onClose={handleOnCloseCategoryModal}>
        <DialogContent>
          <h3 style={{ textAlign: 'center' }}>Add Category</h3>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { my: 1 },
            }}
            noValidate
            className="new-account-form"
          >
            <FormControl >
              <TextField
                fullWidth
                id="newCategory"
                name="newCategory"
                label="Category"
                value={categoryForm.values.newCategory}
                onChange={categoryForm.handleChange}
                helperText={categoryForm.touched.newCategory && categoryForm.errors.newCategory}
              />
            </FormControl>
            <div className="cta-wrap">
              <a className="btn-common btn-dark" onClick={handleOnCloseCategoryModal}>
                Cancel
              </a>
              <a className="btn-common" onClick={categoryForm.handleSubmit}>
                Submit
              </a>
            </div>
          </Box>
        </DialogContent>
      </Dialog>


      <div className="dashboard-wrap">

        {!!user && (
          <MaterialReactTable
            columns={columns}
            data={accounts || []}
            enableColumnActions={false}
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            enableHiding={false}
            enableEditing
            positionActionsColumn="last"
            positionGlobalFilter="right"
            enableColumnFilters={false}
            renderTopToolbarCustomActions={() => (
              <Box sx={{ display: 'flex', gap: '1rem' }}>
                <button
                  className="btn-common"
                  onClick={() => setCreateModalOpen(true)}
                >
                  Create New
                </button>
                <button
                  className="btn-common"
                  onClick={() => setCategoryModalOpen(true)}
                >
                  Add Category
                </button>
              </Box>
            )}
            enableRowActions={true}
            renderRowActions={({ row, table }) => (
              <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip arrow placement="left" title="Edit">
                  <IconButton color="primary" onClick={() => table.setEditingRow(row)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow placement="right" title="Delete">
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      setaccountToDelete(row.original._id);
                      setDeleteModalOpen(true);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            onEditingRowSave={handleSaveRow}
            displayColumnDefOptions={{
              'mrt-row-actions': {
                header: '', // action header text
              },
            }}
            state={{ isLoading: isTableLoading, pagination }}
            initialState={{
              sorting: [
                {
                  id: 'latestFollowerCount', // sort by followers by default on page load
                  desc: true,
                },
              ],
              showGlobalFilter: true
            }}
            onPaginationChange={setPagination} // hoist pagination state to your state when it changes internally
            muiTablePaginationProps={{
              rowsPerPageOptions: [20, 50, 100, 200, 500],
              labelDisplayedRows: () => {
                return "Page " + (pagination.pageIndex + 1) + " of " + (Math.floor(accounts?.length / pagination.pageSize) + 1);
              },
            }}
            muiSearchTextFieldProps={{
              placeholder: `Search...`,
              sx: {
                minWidth: '300px',
              },
              variant: 'outlined',
            }}
          />
        )}

        {accounts && !user && (
          <PresentationTable
            columns={columns}
            data={accounts}
            isLoading={isTableLoading}
            pagination={pagination}
            setPagination={setPagination}
            totalRowCount={accounts?.length}
          />
        )}

      </div>
    </>
  );
};


export default DashboardTable;
