import Footer from './Footer'
import Header from './Header'
import Navbar from './Navbar'
import { Outlet } from "react-router-dom"
import React from 'react'

function Layout() {
    return (
        <>
            <Header />
            <Navbar />
            <main>
                <Outlet />
            </main>
            <Footer />
        </>
    )
}

export default Layout