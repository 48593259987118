import * as yup from 'yup';

import {
    Box,
    Chip,
    CircularProgress,
    Dialog,
    DialogContent,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { addNewCategory, getCategories, removeCategory } from '../services/categories.service';
import { useAddNewCategoryMutation, useRemoveCategoryMutation } from '../store/categories/categoriesApi';
import { useEffect, useState } from 'react';

import React from 'react';
import { newCategorySchema } from '../utils/schemas';
import { useFormik } from 'formik';
import { useGetCategoriesQuery } from '../store/categories/categoriesApi';

function Categories() {

    /* QUERIES */

    const {
        data: categories,
        isLoading: isCategoryListLoading,
        error: getCategoriesError,
    } = useGetCategoriesQuery()

    const [addNewCategory] = useAddNewCategoryMutation();
    const [removeCategory] = useRemoveCategoryMutation()


    /* MODAL */

    const [categoryModalOpen, setCategoryModalOpen] = useState(false);

    const handleOnCloseCategoryModal = () => {
        setCategoryModalOpen(!categoryModalOpen);
        categoryForm.resetForm();
    };

    const [deleteCategoryModalOpen, setDeleteCategoryModalOpen] = useState(false);

    const handleOnCloseDeleteCategoryModal = () => {
        setDeleteCategoryModalOpen(!deleteCategoryModalOpen);
        deleteCategoryForm.resetForm();
    };


    /* FORMS */

    const categoryForm = useFormik({
        initialValues: {
            newCategory: "",
        },
        validationSchema: newCategorySchema,
        onSubmit: (values, formikHelpers) => {
            if (values) {
                addNewCategory({ 'categoryName': values.newCategory })
                setCategoryModalOpen(false);
                formikHelpers.resetForm();
            }
        },
    });


    const deleteCategoryForm = useFormik({
        initialValues: {
            category: "",
        },
        validationSchema: yup.object({
            category: yup.string('Select category').required("Required")
        })
        ,
        onSubmit: (values, formikHelpers) => {
            if (values) {
                removeCategory(values.category)
                setDeleteCategoryModalOpen(false);
                formikHelpers.resetForm();
            }
        },
    });


    return (
        <>
            {/* ADD CATEGORY MODAL */}

            <Dialog open={categoryModalOpen} onClose={handleOnCloseCategoryModal}>
                <DialogContent>
                    <h3 style={{ textAlign: 'center' }}>Add Category</h3>
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { my: 1 },
                        }}
                        noValidate
                        className="new-account-form"
                    >
                        <FormControl >
                            <TextField
                                fullWidth
                                id="newCategory"
                                name="newCategory"
                                label="Category"
                                value={categoryForm.values.newCategory}
                                onChange={categoryForm.handleChange}
                                helperText={categoryForm.touched.newCategory && categoryForm.errors.newCategory}
                            />
                        </FormControl>
                        <div className="cta-wrap">
                            <a className="btn-common btn-dark" onClick={handleOnCloseCategoryModal}>
                                Cancel
                            </a>
                            <a className="btn-common" onClick={categoryForm.handleSubmit}>
                                Submit
                            </a>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>


            {/* DELETE CATEGORY MODAL */}

            <Dialog open={deleteCategoryModalOpen} onClose={handleOnCloseDeleteCategoryModal}>
                <DialogContent>
                    <h3 style={{ textAlign: 'center' }}>Remove Category</h3>
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { my: 1 },
                        }}
                        noValidate
                        className="new-account-form"
                    >
                        <FormControl >
                            <InputLabel id="demo-simple-select-helper-label">Category</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={deleteCategoryForm.values.category}
                                name='category'
                                label="Category"
                                onChange={deleteCategoryForm.handleChange}
                            >
                                {categories?.map(({ _id, categoryName }) => (
                                    <MenuItem key={categoryName} value={_id}>
                                        {categoryName}
                                    </MenuItem>
                                ))}
                            </Select>
                            {deleteCategoryForm.touched.category && deleteCategoryForm.errors.category && (
                                <FormHelperText>{deleteCategoryForm.errors.category}</FormHelperText>
                            )}
                        </FormControl>
                        <div className="cta-wrap">
                            <a className="btn-common btn-dark" onClick={handleOnCloseDeleteCategoryModal}>
                                Cancel
                            </a>
                            <a className="btn-common" onClick={deleteCategoryForm.handleSubmit}>
                                Submit
                            </a>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>


            <Box
                sx={{
                    maxWidth: 960,
                    margin: '0 auto',
                    lineHeight: 1.6,
                    marginTop: '40px',
                    marginBottom: '40px',

                }}
            >
                {localStorage.getItem('user') && (
                    <Box gap={'10px'} display={'flex'} alignItems={'center'}>
                        <button
                            className="btn-common"
                            onClick={() => setCategoryModalOpen(true)}
                        >
                            Add new
                        </button>
                        <button
                            className="btn-common btn-dark"
                            onClick={() => setDeleteCategoryModalOpen(true)}
                        >
                            Remove
                        </button>
                        {isCategoryListLoading && (
                            <Box >
                                <CircularProgress
                                    color='secondary'
                                    size={30}
                                    thickness={4}
                                />
                            </Box>
                        )}
                    </Box>
                )}

                <Box mt={'40px'}>
                    <Typography my={'20px'} color={'primary'} variant="h4" component="h4">Added Categories</Typography>
                    <Box gap={'10px'} display={'flex'} flexWrap={'wrap'}>
                        {categories?.map(({ categoryName, _id }) => (
                            <Chip label={categoryName} key={_id} />
                        ))}
                    </Box>

                </Box>

            </Box>
        </>
    )
}

export default Categories