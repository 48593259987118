import React from 'react'
import { useLocation } from 'react-router-dom';

function Footer() {
    const location = useLocation();
    const slug = location.pathname.replace(/\//g, "");

    return (
        <div className="footer">
            {/* {slug != 'methodology' && (
                <div className="meta">
                    Learn more about our Methodology by clicking
                    <a href='/methodology'> here.</a>
                </div>
            )} */}

            {/* 2023 @ <a href="https://blinkdigital.in/" target="_blank">Blink Digital</a> */}
            {/* <div className="social">
                <a href="https://www.facebook.com/BlinkDigitalIndia/" target="_blank" className="link">
                    <i className="fa fa-facebook" ></i>
                </a>
                <a href="https://www.instagram.com/blink.digital/" target="_blank" className="link">
                    <i className="fa fa-instagram" ></i>
                </a>
                <a href="https://twitter.com/blink_india/" target="_blank" className="link">
                    <i className="fa fa-twitter" ></i>
                </a>
            </div> */}
        </div>
    )
}

export default Footer