import { api } from '../api'

export const accountsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAccounts: builder.query({
            query: () => '/accounts',
            transformResponse: (response, meta, arg) => {
                return response?.sort((a, b) => Number(b.latestFollowerCount) - Number(a.latestFollowerCount));
            },
            providesTags: ['account']
        }),
        getAccountsByCategory: builder.query({
            query: () => '/accounts',
            transformResponse: (response, meta, arg) => {
                return response?.filter((row) => row.category == arg).sort((a, b) => Number(b.latestFollowerCount) - Number(a.latestFollowerCount))
            },
        }),
        addNewAccount: builder.mutation({
            query: body => ({
                url: '/accounts',
                method: 'POST',
                body
            }),
            invalidatesTags: ['account']
        }),
        updateAccount: builder.mutation({
            query: ({ _id, categoryID }) => ({
                url: `/accounts/${_id}`,
                method: 'PUT',
                body: { category: categoryID }
            }),
            invalidatesTags: ['account']
        }),
        removeAccount: builder.mutation({
            query: (accountId) => ({
                url: `/accounts/${accountId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['account']
        }),
    })
})

export const { useGetAccountsQuery, useAddNewAccountMutation, useUpdateAccountMutation, useRemoveAccountMutation, useGetAccountsByCategoryQuery } = accountsApi;