import "./styles/main.scss";
import "font-awesome/css/font-awesome.min.css";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { createRoot } from "react-dom/client";
import { store } from "./store/store"
import theme from "./styles/theme";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
