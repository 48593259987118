import {
    Box,
    Link,
    List,
    Typography
} from '@mui/material';

import React from 'react'

function Methodology() {
    return (
        <Box
            sx={{
                maxWidth: 960,
                margin: '0 auto',
                lineHeight: 1.6,
                marginTop: '40px',
                marginBottom: '40px'
            }}
            className='methodology'
        >
            <Typography variant="h3" component="h2" sx={{ color: 'primary', textAlign: 'center' }}>
                Methodology
            </Typography>
            <br />
            <Typography variant="body1" component='p'>CoinMarketCap strives to provide accurate, timely, and unbiased data for cryptoassets. Our view is that we should always over-provide data (as opposed to censoring and policing information) and let our users draw their own conclusions. We actively listen to feedback/requests from our users to enrich the quality of the data on our website.

                In this connection, please review the following before submitting a request:</Typography>
            <ul>
                <li>Listings Criteria: <a href="https://support.coinmarketcap.com/hc/en-us/articles/360043659351-Listings-Criteria">https://support.coinmarketcap.com/hc/en-us/articles/360043659351-Listings-Criteria</a></li>
                <li>Methodology (Market Data and Cryptoasset Rank): <a href="https://support.coinmarketcap.com/hc/en-us/sections/360008888252-Metric-Methodologies">https://support.coinmarketcap.com/hc/en-us/sections/360008888252-Metric-Methodologies</a> </li>
            </ul>

        </Box>
    )
}

export default Methodology