import * as yup from 'yup';

const newAccountValidationSchema = yup.object({
    handleName: yup.string("Enter handle name").required("Required"),
    //category: yup.string("Select category").required("Required")
    // passcode: yup
    //     .string('Enter your passcode')
    //     .min(8, 'Passcode should be of minimum 8 characters length')
    //     .required('Required'),
});

const loginSchema = yup.object({
    username: yup.string("Enter username").required("Required"),
    password: yup
        .string('Enter your password')
        .required('Required'),
    //.min(8, 'Passcode should be of minimum 8 characters length')
});

const newCategorySchema = yup.object({
    newCategory: yup.string('Enter category').required("Required")
})


export { newAccountValidationSchema, loginSchema, newCategorySchema };