import { api } from '../api'

export const categoriesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getCategories: builder.query({
            query: () => '/categories',
            transformResponse: (response, meta, arg) => {
                const sortedResponse = response?.sort((a, b) => {
                    var textA = a.categoryName.toUpperCase();
                    var textB = b.categoryName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })
                return sortedResponse
            },
            providesTags: ['categories']
        }),
        addNewCategory: builder.mutation({
            query: body => ({
                url: '/categories',
                method: 'POST',
                body
            }),
            invalidatesTags: ['categories']
        }),
        removeCategory: builder.mutation({
            query: (accountId) => ({
                url: `/categories/${accountId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['categories']
        }),
    })
})

export const { useAddNewCategoryMutation, useGetCategoriesQuery, useRemoveCategoryMutation } = categoriesApi;