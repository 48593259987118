import {
    Box,
    Button,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { NavLink } from 'react-router-dom';
import { useGetCategoriesQuery } from '../store/categories/categoriesApi';

const Navbar = () => {

    /* QUERIES */

    const {
        data: categories,
        isLoading: isCategoryListLoading,
        error: getCategoriesError,
    } = useGetCategoriesQuery()

    /* BIGMENU */

    const [menu, setMenu] = useState(null);
    const open = Boolean(menu);
    const handleClick = (e) => {
        setMenu(e.currentTarget);
    };

    const [showNavbar, setShowNavbar] = useState(false)
    const navigate = useNavigate()

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }

    /* GET USER */

    const [user, setuser] = useState(null)

    useEffect(() => {
        let savedUser = localStorage.getItem("user");
        if (savedUser)
            setuser(savedUser)
    }, [])

    const logOut = () => {
        localStorage.removeItem("user");
        window.location.reload(false);
    }

    return (
        <nav className="navbar">
            <div className="content">
                <NavLink to="/" className="main-logo">
                    {/* <img src={Brand} className="img-responsive" alt='brand logo' /> */}
                    <span>BrandTrack</span>
                </NavLink>
                <Box style={{ position: 'relative', flex: 1, paddingLeft: '40px' }}>
                    <Button
                        id="button"
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        Ranking by Categories <ArrowDropDownIcon />
                    </Button>
                    <Menu
                        id="menu"
                        anchorEl={menu}
                        open={open}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        onClose={() => setMenu(null)}
                        sx={{
                            boxShadow: 'box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);'
                        }}
                    >
                        {categories?.map(({ categoryName, _id }, index) => {
                            return (
                                <MenuItem onClick={() => { navigate(`/categories/${_id}`); setMenu(null); }} key={index}>
                                    <ListItemText>{categoryName}</ListItemText>
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </Box>
                <div className="menu-icon" onClick={handleShowNavbar}>
                    {/* {showNavbar ? (<svg style={{ width: '1.5rem', height: '1.5rem' }} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"><line x1="5" y1="5" x2="20" y2="20" stroke="#000" stroke-width="2" stroke-miterlimit="10"></line><line x1="5" y1="20" x2="20" y2="5" stroke="#000" stroke-width="2" stroke-miterlimit="10"></line></svg>)
                        :
                        (<svg style={{ width: '1.5rem', height: '1.5rem' }} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="4" y="5" width="16" height="2"></rect><rect x="4" y="11" width="16" height="2"></rect><rect x="4" y="17" width="16" height="2"></rect></svg>)} */}
                    {user && (
                        <Tooltip title={user ? "Log Out" : "Log In"} >
                            <IconButton onClick={() => user ? logOut() : navigate('/login')}>
                                {user ? <LogoutIcon /> : <LoginIcon />}
                            </IconButton>
                        </Tooltip>
                    )}


                </div>
                {/* <div className={`nav-elements  ${showNavbar && 'active'}`}>
                    <ul>
                        <li>
                            <NavLink to="/about">About</NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact">Contact</NavLink>
                        </li>
                    </ul>
                </div> */}
            </div>
            <div className={`navbar-overlay ${showNavbar && 'active'}`} onClick={() => setShowNavbar(false)}></div>
        </nav>
    )
}

export default Navbar