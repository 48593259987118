import {
    Box,
    Dialog,
    DialogContent,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Route, Routes, useParams } from 'react-router-dom';

import LineChart from '../components/LineChart'
import React from 'react'

function AccountPage() {
    let { id } = useParams();
    const chartData = [
        {
            name: "Mobile apps",
            data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
        },
        // {
        //     name: "Websites",
        //     data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
        // },
    ];
    return (
        <Box
            sx={{
                maxWidth: 960,
                margin: '0 auto',
                lineHeight: 1.6,
                marginTop: '40px',
                marginBottom: '40px'
            }}
            className='methodology'
        >
            <Typography align='center' mb={'20px'} variant="h5" component="h2">{id}</Typography>
            <LineChart chartData={chartData} />
        </Box>
    )
}

export default AccountPage