import React, { useMemo, useState } from "react";
import { Route, Routes } from "react-router-dom";

import AccountPage from "./pages/AccountPage";
import AuthContext from '../src/context/AuthContext'
import Categories from "./pages/Categories";
import CategoryPage from "./pages/CategoryPage";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import Login from './pages/Login'
import Methodology from "./pages/Methodology";
import NotFound from "./pages/NotFound";

function App() {

  const [user, setUser] = useState(null)

  const authContext = useMemo(
    () => ({
      user, setUser
    }),
    [user]
  );

  return (
    <AuthContext.Provider value={authContext} >
      <Routes>
        <Route element={<Layout />} >
          <Route path="/" element={<Home />} />
          <Route path="/methodology" element={<Methodology />} />
          <Route path="/accounts/:id" element={<AccountPage />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/categories/:id" element={<CategoryPage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </AuthContext.Provider>
  );
}

export default App;
