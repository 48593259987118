import {
    Box,
    Link
} from '@mui/material';
import React, { useMemo, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MaterialReactTable from 'material-react-table';
import { useGetAccountsByCategoryQuery } from '../store/accounts/accountsApi';
import { useLocation } from 'react-router-dom';

function CategoryPage() {
    const location = useLocation()
    const slug = location.pathname.split('/').pop();

    const {
        data: accounts,
        isLoading: isAccountListLoading
    } = useGetAccountsByCategoryQuery(slug)

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    });

    const columns = useMemo(() => [
        {
            accessorKey: 'index',
            header: 'Rank',
            size: 50,
            accessorFn: (row, i) => i + 1,
            muiTableBodyCellEditTextFieldProps: {
                disabled: true
            }
        },
        {
            accessorKey: "handleName",
            header: "Handle Name",
            Cell: ({ cell }) => {
                return (
                    <Link
                        sx={{
                            transition: 'all 0.1s ease',
                            textDecoration: 'none',
                            "&:hover": {
                            },
                        }}
                    >
                        {cell.getValue()}
                    </Link >);
            },
        },
        {
            accessorKey: "category",
            header: "Category",
            accessorFn: (row, i) => row.categories.length > 0 ? row.categories[0].categoryName : '',
        },
        {
            accessorKey: "latestFollowerCount",
            header: "Followers",
            accessorFn: (row, i) => Number(row.latestFollowerCount),
            muiTableBodyCellEditTextFieldProps: {
                disabled: true
            },
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
        {
            accessorKey: 'followerDifference',
            header: '24H Growth',
            Cell: ({ cell }) => {
                let perCentageChangeCopy;
                const { followerDifference, percentageChange } = cell.row.original;
                const indexCharacter = String(Number(followerDifference))[0];
                const isGrowthPositive = indexCharacter !== '-';
                const followerDifferenceCopy = isGrowthPositive ? Number(followerDifference) : String(followerDifference).substring(1)

                if (isGrowthPositive) {
                    if (indexCharacter == '0') {
                        perCentageChangeCopy = String(Number(percentageChange)) + ' %'
                    } else {
                        perCentageChangeCopy = String(percentageChange).substring(0, 5) + ' %'
                    }
                } else {
                    perCentageChangeCopy = String(Number(percentageChange)).substring(1, 6) + ' %'
                }

                return (
                    <Box >
                        <Box paddingLeft={'7px'} marginBottom={'-5px'} sx={{ fontFamily: "Circular-Bold", color: isGrowthPositive ? '#16c784' : '#ef737f' }}>
                            {followerDifferenceCopy}
                        </Box>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} sx={{ color: isGrowthPositive ? '#16c784' : '#ef737f', textAlign: 'left', fontSize: '12px' }}>
                            {isGrowthPositive ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            {perCentageChangeCopy}
                        </Box>
                    </Box>
                )
            },
            accessorFn: (row, i) => Number(row.followerDifference),
            muiTableBodyCellEditTextFieldProps: {
                disabled: true
            },
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                // align: 'left',
            },
        },
        {
            accessorKey: 'sevenDayFollowerDifference',
            header: '7D Growth',
            accessorFn: (row, i) => Number(row.sevenDayFollowerDifference),
            muiTableBodyCellEditTextFieldProps: {
                disabled: true
            },
            Cell: ({ cell }) => {
                const { latestFollowerCount, sevenDayPreviousFollowerCount, sevenDayPercentageChange, sevenDayFollowerDifference } = cell.row.original;
                const difference = Number(latestFollowerCount) - Number(sevenDayPreviousFollowerCount);
                const isGrowthPositive = difference >= 0;

                return (
                    <Box >
                        <Box paddingLeft={'7px'} marginBottom={'-5px'} sx={{ fontFamily: "Circular-Bold", color: isGrowthPositive ? '#16c784' : '#ef737f' }}>
                            {String(Math.abs(difference))}
                        </Box>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} sx={{ color: isGrowthPositive ? '#16c784' : '#ef737f', textAlign: 'left', fontSize: '12px' }}>
                            {isGrowthPositive ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            {String(Math.abs(sevenDayPercentageChange)).substring(0, 5) + ' %'}
                        </Box>
                    </Box>
                )
            }
        }
    ]);

    return (
        <div className="dashboard-wrap">

            <MaterialReactTable
                columns={columns}
                data={accounts || []}
                enableColumnActions={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                positionGlobalFilter="right"
                enableColumnFilters={false}
                enableRowActions={false}
                state={{ isLoading: isAccountListLoading, pagination }}
                initialState={{
                    sorting: [
                        {
                            id: 'latestFollowerCount', // sort by followers by default on page load
                            desc: true,
                        },
                    ],
                    showGlobalFilter: true
                }}
                onPaginationChange={setPagination} // hoist pagination state to your state when it changes internally
                muiTablePaginationProps={{
                    rowsPerPageOptions: [20, 50, 100, 200, 500],
                    labelDisplayedRows: () => {
                        return "Page " + (pagination.pageIndex + 1) + " of " + (Math.floor(accounts?.length / pagination.pageSize) + 1);
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Search...`,
                    sx: {
                        minWidth: '300px',
                    },
                    variant: 'outlined',
                }}
            />

        </div>
    )
}

export default CategoryPage