import { Helmet } from "react-helmet";
import React from 'react'

function Header() {
    return (
        <Helmet>
            <title>BrandTrack</title>
            <meta name="description" content="Track brand metrics and performance" />
        </Helmet>
    )
}

export default Header